import React, { Component } from "react"
import GoogleMapsJSLoaderContextHOC from "./contexts/GoogleMapsJSLoaderContextHOC"
import "./css/GoogleAutocomplete.css"

class GoogleAutocomplete extends Component {

  constructor(props){
    super(props)
    this.state = {
      google_maps_loaded:false
    }
    window.a = this
  }
  
  componentDidMount(){
    if(this.props.GoogleMapsJSLoader.google_maps_loaded){
      this.setState({google_maps_loaded:true}, ()=>this.initAutocomplete())
    }   
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.props.GoogleMapsJSLoader.google_maps_loaded &&
      !this.state.google_maps_loaded
    ){
      this.setState({google_maps_loaded:true}, ()=>this.initAutocomplete())
    }
  }


  initAutocomplete() {
    if(!this.state.google_maps_loaded) return
    var input = document.getElementById('places-search');
    let options = {
      types:['establishment']
    }
    this.autocomplete = new window.google.maps.places.Autocomplete(input, options);
    let that = this
    this.autocomplete.addListener('place_changed', function (e) {
      that.setState({place:that.autocomplete.getPlace()})
      that.props.updatePlace(that.autocomplete.getPlace())
    })
  }

  render() {
    return(
      <div>
        <input className='google-location-input' id='places-search' placeholder='Your restaurant or address'/>
      </div>
    )
  }
}
export default GoogleMapsJSLoaderContextHOC(GoogleAutocomplete) 
