/*
  Params:
    endpoint -> name of the endpoint
    pin -> the rest pin, used for authneticated API calls
    success -> success callback, receieves the json decoded response from endpoint
    failure -> error call back, receives the error
    data -> post data (should be oejct)
    url_uparams -> url parameters as object for GET requests
*/

const callApi = params => {
  let endpoint = params.endpoint;
  const success = params.success ? params.success : () => {};
  const failure = params.failure ? params.failure : () => {};
  const data = params.data ? params.data : {};
  // const url_params = data.url_params ? data.url_params : {};
  const order_id = params.order_id ? params.order_id : null
  let apiContent = 'json'

  let method = 'get';

  if (endpoint === 'save-place'){
    method = 'post'
    endpoint = '/api/onboarding/connect-user-to-restaurant'
  }

  if (endpoint === 'check-code'){
    method = 'post'
    endpoint = '/api/onboarding/check-login-code'
  }
  
  if (endpoint === 'get-code'){
    method = 'post'
    endpoint = '/api/onboarding/get-login-code'
  }

  if (endpoint === 'save-pos'){
    method ='post'
    endpoint = '/api/onboarding/save-pos'
    endpoint = '/api/onboarding/save-pos'
  }

  if (endpoint === 'save-user'){
    method = 'post'
    endpoint = '/api/onboarding/save-user'
  }
  
  const url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}${endpoint}`;
  if (method === 'post') {
    let headers = {
      'Content-Type': 'application/json',
      'X-HNGR-API-KEY': window.API_KEY,
    }
    if(order_id){
      headers['X-HNGR-ORDER-ID'] = order_id
    }
  
    if (apiContent === 'json'){
      fetch(url, { method: 'post', headers, body: JSON.stringify(data) })
        .then(jsonresponse => {
          return jsonresponse.json()
        })
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    } else if (apiContent === 'blob'){
      fetch(url, { method: 'post', headers, body: JSON.stringify(data) })
        .then(response => response.blob())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    }
  }

  if (method === 'get') {
    let headers = {
      'X-HNGR-API-KEY': window.API_KEY
    }

    if(order_id){
      headers['X-HNGR-ORDER-ID'] = order_id
    }

    if (apiContent === 'json'){
      fetch(url, { method: 'get', headers })
        .then(jsonresponse => jsonresponse.json())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    } else if (apiContent === 'blob'){
      fetch(url, { method: 'get', headers })
        .then(response => response.blob())
        .then(response => {
          success(response);
        })
        .catch(err => {
          console.log(err);
          failure(err);
        });
    }
  }
};

module.exports = {
  callApi,
};
