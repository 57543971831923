import React, { Component } from "react"
import "./css/Footer.css"


class Footer extends Component {
    render() {
        return (
            <div className='footer'>
            
                <div className='flag'> 
                    <img className='flag-img' src='/captainlogo.png'></img> 
                    <div className='copyright-text'>© 2021 Captain</div>
                </div>

                <div className='links'>
                    <a href='https://www.gocaptain.com/solutions' target='_blank'> Solutions </a>
                    <a href='https://gocaptain.com/about' target='_blank'> About</a>
                    <a href='https://gocaptain.com/careers' target='_blank'> Careers</a>
                    <a href='https://gocaptain.com/contact' target='_blank'> Contact Us </a>
                    <a href='https://gocaptain.com/blog' target='_blank'> Blog </a>
                    <a href='https://gocaptain.com/support' target='_blank'> Support </a>
                    <a href='https://gocaptain.com/login' target='_blank'> Login </a>
                    <a href='https://www.facebook.com/Captain-103951701740487' target='_blank'>
                        <img src='facebook-icon.png'></img>
                    </a>
                    <a href='https://www.linkedin.com/company/gocaptain' target='_blank'>
                        <img src='linkedin-icon.png'></img>
                    </a>
                </div>
          
          </div>
        )
    }

}

export default Footer; 