import React, { Component } from "react"
import "./css/DownloadCaptain.css"
import Header from "./Header.js"
import Footer from "./Footer.js"
import Subheader from "./Subheader.js"
import ProgressBar from "./ProgressBar"

class DownloadCaptain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450,
      rest_name: null,
      loading:false
    }
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }


  nextPage(){
    this.props.history.push({
      pathname:'/complete'
      // state:{
      //   place:this.state.place
      // }
    })
  }

  renderDesktop(){
    return(
      <div className='download-container'>
        <Header/>
        <div className='download-inner-container'>
          <div className='download-captain-left-side'>
            <ProgressBar page='4'/>
            <div className='download-captain-container'>
              <div className='download-text-container'>
                <img className='download-captain-logo' src='/download-captain-logo.png'></img>
                <div className='download-captain-text'>Download the Captain app today</div>
                <div className='download-captain-subtext'>Get back in the driver’s seat at your restaurant, and let us help you drive the orders.</div>
                <div className='download-captain-subtext'>The Captain app allows you to manage orders and build relationships with customers at your desk or on-the-go. Accept or cancel orders, issue discounts or upcharge requests, and view customer data insights- all from your phone or tablet. </div>
                <div className='download-captain-subtext'>
                  <div>
                    Download the Captain app to get paid with <img src='/stripe-logo.png' style={{marginLeft:'5px', position:'relative', top:'4px'}}></img>
                  </div>
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.captain.app" target='_blank'>
                  <button className='google-play-button-container'>
                    <img className='download-google-play' src='/google-play-store.png'></img>
                  </button>
                </a>
                <a href="https://apps.apple.com/app/id1578014221" target="_blank">
                  <button className='mobile-google-play-button-container'>
                    <img className='mobile-download-google-play' src='/appstore.png'></img>
                  </button>
                </a>

                <div className='next-box'>
                  <button className='next-button' onClick={()=>{this.nextPage()}}> 
                    Next
                  </button>
                </div>

                <div className='download-contact-us'>
                  <div style={{marginRight:'5px'}}>Need help? </div>
                  <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <div className='download-captain-right-side'>
            <img style={{width: '590px'}} className='download-mockup' src='/phonescreen.png'></img>
          </div>
        </div>
      </div> 
    )
  }

  renderMobile(){
    return(
      <div className='download-container'>
        <Header/>
        <div className='mobile-download-inner-container'>
          <div className='mobile-download-captain-left-side'>
            <ProgressBar page='4'/>
            <div className='mobile-download-captain-container'>
              <div className='mobile-download-text-container'>
                <img className='mobile-download-captain-logo' src='/download-captain-logo.png'></img>
                <div className='mobile-download-captain-text'>Download the Captain app today</div>
                <div className='mobile-download-captain-subtext'>Get back in the driver’s seat at your restaurant, and let us help you drive the orders.</div>
                <div className='mobile-download-captain-subtext'>The Captain app allows you to manage orders and build relationships with customers at your 
                desk or on-the-go. Accept or cancel orders, issue discounts or upcharge requests, and view customer data insights- all from your phone or tablet. </div>
                <div className='mobile-download-captain-subtext'>
                  <div>
                    Download the Captain app to get paid with <img src='/stripe-logo.png' style={{marginLeft:'2px', position:'relative', top:'5px'}}></img>
                  </div>
                </div>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.captain.app" target='_blank'>
                    <button className='mobile-google-play-button-container'>
                      <img className='mobile-download-google-play' src='/google-play-store.png'></img>
                    </button>
                  </a>
                  <a href="https://apps.apple.com/app/id1578014221" target="_blank">
                    <button className='mobile-google-play-button-container'>
                      <img className='mobile-download-google-play' src='/appstore.png'></img>
                    </button>
                  </a>
                </div>

                <div className='mobile-next-box'>
                  <button className='mobile-next-button' onClick={()=>{this.nextPage()}}> 
                    Next
                  </button>
                </div>
                  
                <div className='mobile-download-contact-us'>
                  <div style={{marginRight:'5px'}}>Need help? </div>
                  <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    )
  }

  render(){
    return (
      this.state.isMobile ? 
        this.renderMobile()
        : this.renderDesktop()
    )
  }
}
      

export default DownloadCaptain; 


