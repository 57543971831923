import React, { Component } from "react"
import "./css/GMBOauth.css"
import Header from "./Header.js"
import Footer from "./Footer.js"
import Subheader from "./Subheader.js"
import ProgressBar from "./ProgressBar"

class Restaurant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450,
      loading:false
    }
    this.handleResize = this.handleResize.bind(this)
    
    if(!(
      this.props.location && 
      this.props.location.state &&
      this.props.location.state.rest_uuid
    )){
      this.props.history.replace({
        pathname:'/start'
      })  
    } else {
      this.rest_uuid = this.props.location.state.rest_uuid
      this.restaurant_name = this.props.location.state.restaurant_name
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  nextPage(){
    this.props.history.push({
      pathname:'/pos',
      state:{
        rest_uuid: this.props.location.state.rest_uuid
      }
    })
  }

  // getLink(){
  //   let redirect_uri = encodeURIComponent(`${process.env.REACT_APP_ONBOARDING_LOCATION}/pos?rest_uuid=${this.rest_uuid}`)
  //   let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/gmb/auth_begin?redirect_uri=${redirect_uri}`
  //   return url
  // }

  renderDesktop(){
    return(
      <div className='gmb-container'>
        <Header/>
        <div className='gmb-inner-container'>
          <div className='gmb-left-side'>
            <ProgressBar page='2'/>
            <div className='connect-restaurant-container'>
              <div className='connect-restaurant-text'>Approve our Request</div>
              <div className='connect-restaurant-subtext'>After registration, you will receive an email from our team requesting 
                access to your Google My Business listing. The email is titled:
              </div>
              <div className='connect-restaurant-subtext' style={{fontWeight:'900'}}>You’ve requested access to {this.restaurant_name} on Google My Business
              </div>
              <div className='connect-restaurant-subtext'>Please open the email from your account attached to the GMB listing and approve the 
                following request.
              </div>
              
              <img src='./gmb-sample-email.png'></img>

              <div className='gmb-continue-button' onClick={()=>this.nextPage()}>I Understand</div>
              

              <div className='gmb-contact-us'>
                <div style={{marginRight:'5px'}}>Need help? </div>
                <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
              </div>
            </div>
          </div>
          <div className='gmb-right-side'>
            <img style={{width: '465px'}} className='gmb-right-side-image' src='/gmb.png'></img>
          </div>
        </div>
      
      </div>
    )
  }

  renderMobile(){
    return(
      <div className='gmb-container'>
        <Header/>
        <div className='mobile-gmb-inner-container'>
          <div className='mobile-gmb-left-side'>
            <ProgressBar page='2'/>
            <div className='mobile-connect-restaurant-container'>
              <div className='mobile-connect-restaurant-text'>Approve our Request</div>
              <div className='mobile-connect-restaurant-subtext'>After registration, you will receive an email from our team requesting 
                access to your Google My Business listing. The email is titled:
              </div>
              <div className='mobile-connect-restaurant-subtext' style={{fontWeight:'800'}}>You’ve requested access to {this.restaurant_name} on Google My Business
              </div>
              <div className='mobile-connect-restaurant-subtext'>Please open the email from your account attached to the GMB listing and approve the 
                following request.
              </div>
              
              <img src='./gmb-sample-email.png'></img>

              <div className='gmb-continue-button' onClick={()=>this.nextPage()}>I Understand</div>

              <div className='mobile-gmb-contact-us'>
                <div style={{marginRight:'5px'}}>Need help? </div>
                <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render(){
    return (
      this.state.isMobile ? 
        this.renderMobile()
        : this.renderDesktop()
    )
  }
}
      

export default Restaurant; 


