import React, { Component } from "react"
import "./css/Header.css"


class Header extends Component {
    render() {
        return (
            <div className='header'>
                <div className='captain-logo'> 
                    <img style={{height:'40px', width:'auto'}} src='/white-header-logo2.png'></img>
                </div>

            </div>
        )
    }

}

export default Header; 
