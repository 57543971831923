import React from 'react';
import ReactDOM from 'react-dom';
import StartPage from "./StartPage"
import { BrowserRouter, Route } from "react-router-dom"
// import ConfirmCell from './ConfirmCell';
import { Redirect } from "react-router-dom"
import Restaurant from './Restaurant';
import GoogleMapsJSLoaderProvider from "./contexts/GoogleMapsJSLoaderContext"
import DownloadCaptain from './DownloadCaptain';
import GMBOauth from "./GMBOauth"
import PointOfSale from "./PointOfSale"
import ConfirmPhoneModal from "./ConfirmPhoneModal"
import CompleteActivation from "./CompleteActivation"
import GoogleAutocomplete from "./GoogleAutocomplete"
import LogRocket from 'logrocket';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./css/app.css"
if (process.env.REACT_APP_LOGROCKET){
  LogRocket.init(process.env.REACT_APP_LOGROCKET)
}

if(process.env.REACT_APP_ONBOARDING_SENTRY_DSN){
  Sentry.init({
    dsn: process.env.REACT_APP_ONBOARDING_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

class App extends React.Component {

  render() {
    return (
      <div style={{width:'100%', height:'100%'}}>
        <Route
          path="/google"
          key="google"
          component={GoogleAutocomplete}
        />

        <Route exact key="root" path="/">
          <Redirect to="/start" />
        </Route>

        <Route
          path="/start"
          key="startpage"
          component={StartPage}
        />

        <Route
          path="*/modal"
          key="modal"
          component={ConfirmPhoneModal}
        />

        <Route
          path="/restaurant"
          exact
          key="restaurant"
          component={Restaurant}
        />


        <Route
          path="/gmb-oauth"
          key="gmb"
          component={GMBOauth}
        />

        <Route 
          path="/pos"
          exact
          key="pos"
          component={PointOfSale}
        />

        <Route
          path="/download-captain"
          exact
          key="download captain"
          component={DownloadCaptain}
        />

        <Route
          path="/complete"
          exact
          key="activation complete"
          component={CompleteActivation}
        />

      </div>
    )
  }

}
ReactDOM.render(
  <BrowserRouter>
    <GoogleMapsJSLoaderProvider>
      <App />
    </GoogleMapsJSLoaderProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
