import React, { Component } from "react"
import "./css/StartPage.css"
import Header from "./Header.js"
import Footer from "./Footer.js"

const Api = require("./api")

class StartPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450,
      first_name:'',
      last_name: '',
      phone:'',
      email:'',
      loading:false
    }
    this.buttonClick = this.buttonClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  sendCodeAndOpenModal(){


    if (this.state.first_name === '') {
      alert("Don't forget to fill out your first name")
      return
    }

    else if (this.state.last_name === '') {
      alert("Don't forget to fill out your last name")
      return
    }

    else if (this.state.email === '') {
      alert("Don't forget to fill out your email")
      return
    }

    else if (this.state.phone === '') {
      alert("Don't forget to fill out your phone")
      return
    }

    else if (this.state.phone.length !== 12) {
      alert("Not a valid phone number")
      return
    }

    else{
      // hit the api to send a login code to the user.
      // if the api succeeds, send them to the /confirm page
      // if it fails, alert an error
      Api.callApi({
        endpoint:'get-code',
        data:{
          phone:this.state.phone
        },
        success:(data)=>{
          if(data.success === 1){
            let auth_uuid = data.auth_uuid
            this.props.history.push({
              pathname:'/start/modal',
              state:{
                auth_uuid:auth_uuid,
                first_name:this.state.first_name,
                last_name:this.state.last_name,
                email:this.state.email,
                phone:this.state.phone
              }
            })
          } else if (data.success === 0){
            alert(data.error)
          }
        },
        failure:(err) =>{
          alert('Unexpected error occured')
        }
      })
    }

  }

  buttonStyle(){
    if(
      this.state.first_name.length > 0 && 
      this.state.last_name.length > 0 && 
      this.state.email.length > 0 && 
      this.state.phone.length === 12
    ){
      return {
        background:'linear-gradient(41.88deg, #0086B7 0%, #00BED4 100%)'
      }
    } else{
      return {
        backgroundColor:'#AAABAF'
      }
    }
  }



  handleInputPhone(e) {
    var cleaned = e.target.value.replace(/\D/g, "")
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(0, 10)
    }
    if (cleaned.length === 10) {
      cleaned =
        cleaned.slice(0, 3) +
        "-" +
        cleaned.slice(3, 6) +
        "-" +
        cleaned.slice(6, cleaned.length)
    }
    this.setState({ phone: cleaned})
  }

  buttonClick(){
    this.setState({loading:false})
  }

  handlePhone(e){
    this.setState({phone:e.target.value})
  }

  

  renderDesktop(){
    return (
      <div className='container'>
          <Header/>
          <div className='inner-container'>
            <div className='left-side'>
              <div className='create-account-container'>
                  <div className='start-gfo-text'>Google Food Ordering</div>
                  <div className='start-gfo-subtext'>
                    <div style={{paddingRight:'4px'}}>Powered By</div> 
                    <div style={{fontWeight:'800'}}> Captain</div>
                  </div>
                  <div className='create-account'>Create your account</div>
                  <div className='create-account-subtext'>Get started with Google Food Ordering powered by Captain.  Please fill out the following information to get your account set up.</div>
                  <div>First Name</div>
                  <input
                    className='text-input start-text-input'
                    value={this.state.first_name}
                    placeholder='Jack'
                    onChange={(e)=>this.setState({first_name:e.target.value})}
                    required
                  />
                  
                  <div>Last Name</div>
                  <input
                    className='text-input start-text-input'
                    value={this.state.name}
                    onChange={(e)=>this.setState({name:e.target.value})}
                    value={this.state.last_name}
                    placeholder='Sparrow'
                    onChange={(e)=>this.setState({last_name:e.target.value})}
                    required
                  />
                  
                  <div>Personal Email</div>
                  <input
                    className='text-input start-text-input'
                    value={this.state.email}
                    placeholder='captainjack@gocaptain.com'
                    onChange={(e)=>this.setState({email:e.target.value})}
                    required
                  />
                  
                  <div>Personal Cell Phone</div>
                  <input
                    className='text-input start-text-input'
                    value={this.state.phone}
                    placeholder='(555) 555-5555'
                    onChange={(e) => {this.handleInputPhone(e)}}
                    required
                  />

                  {/* {this.state.first_name === 'scott' && (
                    <div> your name is scott! </div>
                  )} */}

                  {/* {this.renderScottsName()} */}
                  <div className='start-bottom-elements-container'>
                    <div className='start-page-contact-us'>
                    <div style={{marginRight:'5px'}}>Need help? </div>
                      <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                    </div>

                    <div className='continue-box'>
                      <button style={this.buttonStyle()} className='continue-button' onClick={()=>this.sendCodeAndOpenModal()}> 
                        Continue
                      </button>
                    </div>
                  </div>
              </div>
              
            </div>
            <div className='right-side'>
              <img style={{width: '465px'}} src='/startpagerightimage.png'></img>
            </div>
          </div>

          
          
      
      </div>
    )
  }

  renderMobile(){
    return(
      <div className='container'>
          <Header/>
          <div className='mobile-inner-container'>
            <div className='mobile-left-side'>
              <div className='mobile-create-account-container'>
                  <div className='mobile-start-gfo-text'>Google Food Ordering</div>
                  <div className='mobile-start-gfo-subtext'>
                    <div style={{paddingRight:'4px'}}>Powered By</div> 
                    <div style={{fontWeight:'800'}}> Captain</div>
                  </div>
                  <div className='mobile-create-account'>Create your account</div>
                  <div className='mobile-create-account-subtext'>Get started with Google Food Ordering powered by Captain.  Please fill out the following information to get your account set up.</div>
                  <div className='mobile-input-title'>First Name</div>
                  <input
                    className='mobile-text-input mobile-start-text-input'
                    value={this.state.first_name}
                    placeholder='Jack'
                    onChange={(e)=>this.setState({first_name:e.target.value})}
                    required
                  />
                  
                  <div className='mobile-input-title'>Last Name</div>
                  <input
                    className='mobile-text-input mobile-start-text-input'
                    value={this.state.name}
                    onChange={(e)=>this.setState({name:e.target.value})}
                    value={this.state.last_name}
                    placeholder='Sparrow'
                    onChange={(e)=>this.setState({last_name:e.target.value})}
                    required
                  />
                  
                  <div className='mobile-input-title'>Personal Email</div>
                  <input
                    className='mobile-text-input mobile-start-text-input'
                    value={this.state.email}
                    placeholder='captainjack@gocaptain.com'
                    onChange={(e)=>this.setState({email:e.target.value})}
                    required
                  />
                  
                  <div className='mobile-input-title'>Personal Cell Phone</div>
                  <input
                    className='mobile-text-input mobile-start-text-input'
                    value={this.state.phone}
                    placeholder='(555) 555-5555'
                    onChange={(e) => {this.handleInputPhone(e)}}
                    required
                  />

                  <div className='mobile-start-bottom-elements-container'>
                    <div className='mobile-start-page-contact-us'>
                    <div style={{marginRight:'5px'}}>Need help? </div>
                      <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                    </div>

                    <div className='mobile-continue-box'>
                      <button style={this.buttonStyle()} className='mobile-continue-button' onClick={()=>this.sendCodeAndOpenModal()}> 
                        Continue
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
  
  render(){
    return (
      this.state.isMobile ? 
        this.renderMobile()
        : this.renderDesktop()
    )

  }
}
      

export default StartPage; 


