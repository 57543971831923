import React, { Component } from "react"
import "./css/PointOfSale.css"
import Header from "./Header.js"
import Subheader from "./Subheader.js"
import ProgressBar from "./ProgressBar"

const Api = require("./api")

class PointOfSale extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450,
      loading:false,
      pos: '',
      other: false,
      noPOS: false
    }


    const urlParams = new URLSearchParams(window.location.search);
    const rest_uuid = this.props.location.state.rest_uuid;
    // const error = urlParams.get('error');

    // if (error){
    //   this.props.history.replace({
    //     pathname:"/gmb-oauth/gmb-modal?error='alert'",
    //     state:{
    //       rest_uuid: rest_uuid
    //     }
    //   })
    // }
    
    if(!rest_uuid){
      this.props.history.replace({
        pathname:'/start'
      })  
    } else{
      this.rest_uuid = rest_uuid
    }

    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  getSquareOauthLink(){
    let redirect_uri = encodeURIComponent(`${process.env.REACT_APP_ONBOARDING_LOCATION}/download-captain`)
    let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/square/oauth/begin?redirect_uri=${redirect_uri}`
    return url 
  }


  savePOSandContinue(){
    if(!this.state.pos || this.state.pos === 'unselected'){
      alert('Please select a POS')
      return
    }

    Api.callApi({
      endpoint:'save-pos',
      data:{
        rest_uuid:this.rest_uuid,
        pos:this.state.pos
      },
      success:(data)=>{
        if(data.success){
          if(this.state.pos === 'square'){
            //send them to square OAUTH
            window.location.href = this.getSquareOauthLink()
          } else{
            this.props.history.push({
              pathname:'/download-captain'
            })
          }
        } else{
          alert(data.error)
        }
      },
      failure:(err)=>{
        alert('Unknown error')
      }

    })


  }

  getLink(){
    let redirect_uri = encodeURIComponent(`${process.env.REACT_APP_ONBOARDING_LOCATION}/download-captain`)
    let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/gmb/auth_begin?redirect_uri=${redirect_uri}`
    return url
  }

  styleButton(name){
      if (this.state.pos == name) {
          return{
            border: '3px solid #0086B7'
            }
      }
  }

  styleButtonOther(){
    if (this.state.other) {
        return{
            border: '3px solid #0086B7'
          }
    }
  }

  styleButtonNoPOS(){
    if (this.state.noPOS) {
        return{
            border: '3px solid #0086B7'
          }
    }
  }

  styleContinueButton(){
      if (this.state.pos) {
        return{
            background:'linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%)'
        }
      }
  }

  renderDesktop(){
    return(
      <div className='pos-container'>
        <Header/>
        <div className='pos-inner-container'>
          <div className='pos-left-side'>
            <ProgressBar page='3'/>
            <div className='pos-system-container'>
              <div className='pos-text'>Point-of-Sale System</div>
              <div className='pos-subtext'>Please select your restaurant's point-of-sale system from the list below:</div>
              <div className='pos-bullet-container'>
                <div style={this.styleButton('square')} className='pos-button' onClick={()=>this.setState({pos:'square', other:false, noPOS: false})}>
                    <img src='/square.png'></img>
                </div>
                <div style={this.styleButton('toast')} className='pos-button' onClick={()=>this.setState({pos:'toast', other:false, noPOS: false})}>
                    <img src='/toast.png'></img>           
                </div>
              </div>
              <div className='pos-bullet-container'>
                <div style={this.styleButton('revel')} className='pos-button' onClick={()=>this.setState({pos:'revel', other:false, noPOS: false})}>
                    <img src='/revel.png'></img>
                </div>
                <div style={this.styleButton('upserve')} className='pos-button' onClick={()=>this.setState({pos:'upserve', other:false, noPOS: false})}>
                    <img src='/upserve.png'></img>           
                </div>
              </div>
              <div className='pos-bullet-container'>
                <div style={this.styleButton('aloha')} className='pos-button' onClick={()=>this.setState({pos:'aloha', other:false, noPOS: false})}>
                    <img src='/aloha.png'></img>
                </div>
                <div style={this.styleButton('clover')} className='pos-button' onClick={()=>this.setState({pos:'clover', other:false, noPOS: false})}>
                    <img src='/clover.png'></img>           
                </div>
              </div>
              <div className='pos-bullet-container'>
                <div style={this.styleButtonOther()} className='pos-button-other' onClick={()=>this.setState({pos:'other', other:true, noPOS: false})}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start' }}>
                        <div className='pos-circle'>
                            {this.state.other && 
                                <div className='pos-circle-selected'></div>
                            }
                        </div>
                        Other
                    </div>
                    {this.state.other && 
                        <select onChange={(e) => this.setState({pos: e.target.value})}>
                            <option role="placeholder" value='unselected'>Select an option</option>
                            <option value='granbury'>Granbury</option>
                            <option value='bepoz'>Bepoz</option>
                            <option value='dinerware'>Dinerware</option>
                            <option value='epson printer'>Epson Printer</option>
                            <option value='eatabit'>Eatabit</option>
                            <option value='future'>Future</option>
                            <option value='gusto'>Gusto</option>
                            <option value='infogenesis'>InfoGenesis</option>
                            <option value='lavu'>Lavu</option>
                            <option value='lightspeed'>Lightspeed</option>
                            <option value='micros3700'>Micros3700</option>
                            <option value='micros simphony'>Micros Simphony</option>
                            <option value='microworks'>MicroWorks</option>
                            <option value='ordersnapp'>Ordersnapp</option>
                            <option value='par-brink'>Par-Brink</option>
                            <option value='positouch'>Positouch</option>
                            <option value='speedline'>Speedline</option>
                            <option value='focus'>Focus</option>
                            <option value='revention'>Revention</option>
                            <option value='squirrel'>Squirrel</option>
                            <option value='not listed'>None of These</option>
                        </select>
                    }
                </div>
              </div>

              <div className='pos-bullet-container'>
                <div style={this.styleButtonNoPOS()} className='pos-button-none' onClick={()=>this.setState({pos:'no pos', other:false, noPOS: true})}>
                    <div className='pos-circle'>
                        {this.state.noPOS && 
                            <div className='pos-circle-selected'></div>
                        }
                    </div>
                    I don't use a point-of-sales system
                </div>
              </div>
              
              {/* <a href={this.getLink()} className='gmb-signin'>
                <img src='/google-signin.png'></img>
              </a> */}
              <div className="pos-bottom-elements-container">
                
                <div className='pos-contact-us'>
                    <div style={{marginRight:'5px'}}>Need help? </div>
                    <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                </div>
                
                <div  
                    className='pos-continue-button'
                    onClick={()=>this.savePOSandContinue()}
                    style={this.styleContinueButton()}
                >
                    Continue
                </div>
              </div>
              
            </div>
          </div>
          <div className='pos-right-side'>
            <img style={{width: '465px'}} className='pos-right-side-image' src='/pos.png'></img>
          </div>
        </div>
      
      </div>
    )
  }

  renderMobile(){
    return(
      <div className='pos-container'>
        <Header/>
        <div className='mobile-pos-inner-container'>
          <div className='mobile-pos-left-side'>
            <ProgressBar page='3'/>
            <div className='mobile-pos-system-container'>
            <div className='mobile-pos-text'>Point-of-Sale System</div>
              <div className='mobile-pos-subtext'>Please select your restaurant's point-of-sale system from the list below:</div>
              <div className='mobile-pos-bullet-container'>
                <div style={this.styleButton('square')} className='mobile-pos-button' onClick={()=>this.setState({pos:'square', other:false, noPOS: false})}>
                    <img src='/square.png'></img>
                </div>
                <div style={this.styleButton('toast')} className='mobile-pos-button' onClick={()=>this.setState({pos:'toast', other:false, noPOS: false})}>
                    <img src='/toast.png'></img>           
                </div>
              </div>
              <div className='pos-bullet-container'>
                <div style={this.styleButton('revel')} className='mobile-pos-button' onClick={()=>this.setState({pos:'revel', other:false, noPOS: false})}>
                    <img src='/revel.png'></img>
                </div>
                <div style={this.styleButton('upserve')} className='mobile-pos-button' onClick={()=>this.setState({pos:'upserve', other:false, noPOS: false})}>
                    <img src='/upserve.png'></img>           
                </div>
              </div>
              <div className='pos-bullet-container'>
                <div style={this.styleButton('aloha')} className='mobile-pos-button' onClick={()=>this.setState({pos:'aloha', other:false, noPOS: false})}>
                    <img src='/aloha.png'></img>
                </div>
                <div style={this.styleButton('clover')} className='mobile-pos-button' onClick={()=>this.setState({pos:'clover', other:false, noPOS: false})}>
                    <img src='/clover.png'></img>           
                </div>
              </div>
              <div className='mobile-pos-bullet-container'>
                <div style={this.styleButtonOther()} className='mobile-pos-button-other' onClick={()=>this.setState({pos:'other', other:true, noPOS: false})}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start' }}>
                        <div className='mobile-pos-circle'>
                            {this.state.other && 
                                <div className='mobile-pos-circle-selected'></div>
                            }
                        </div>
                        Other
                    </div>
                    {this.state.other && 
                        <select className='mobile-select' onChange={(e) => this.setState({pos: e.target.value})}>
                            <option role="placeholder" value='unselected'>Select an option</option>
                            <option value='granbury'>Granbury</option>
                            <option value='bepoz'>Bepoz</option>
                            <option value='dinerware'>Dinerware</option>
                            <option value='epson printer'>Epson Printer</option>
                            <option value='eatabit'>Eatabit</option>
                            <option value='future'>Future</option>
                            <option value='gusto'>Gusto</option>
                            <option value='infogenesis'>InfoGenesis</option>
                            <option value='lavu'>Lavu</option>
                            <option value='lightspeed'>Lightspeed</option>
                            <option value='micros3700'>Micros3700</option>
                            <option value='micros simphony'>Micros Simphony</option>
                            <option value='microworks'>MicroWorks</option>
                            <option value='ordersnapp'>Ordersnapp</option>
                            <option value='par-brink'>Par-Brink</option>
                            <option value='positouch'>Positouch</option>
                            <option value='speedline'>Speedline</option>
                            <option value='focus'>Focus</option>
                            <option value='revention'>Revention</option>
                            <option value='squirrel'>Squirrel</option>
                            <option value='not listed'>None of These</option>
                        </select>
                    }
                </div>
              </div>

              <div className='mobile-pos-bullet-container'>
                <div style={this.styleButtonNoPOS()} className='mobile-pos-button-none' onClick={()=>this.setState({pos:'no pos', other:false, noPOS: true})}>
                    <div className='mobile-pos-circle'>
                        {this.state.noPOS && 
                            <div className='mobile-pos-circle-selected'></div>
                        }
                    </div>
                    I don't use a point-of-sales system
                </div>
              </div>
              <div className="mobile-pos-bottom-elements-container">
                
                <div className='mobile-pos-contact-us'>
                    <div style={{marginRight:'5px'}}>Need help? </div>
                    <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
                </div>
                
                <div  
                    className='mobile-pos-continue-button'
                    onClick={()=>this.savePOSandContinue()}
                    style={this.styleContinueButton()}
                >
                    Continue
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render(){
    return (
      this.state.isMobile ? 
        this.renderMobile()
        : this.renderDesktop()
    )
  }
}
      

export default PointOfSale; 


