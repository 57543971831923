import React, { Component } from "react"
import "./css/Restaurant.css"
import Header from "./Header.js"
import Footer from "./Footer.js"
import Subheader from "./Subheader.js"
import GoogleAutocomplete from "./GoogleAutocomplete"
import ProgressBar from "./ProgressBar"
import GoogleMapsJSLoaderProvider from "./contexts/GoogleMapsJSLoaderContext"

const Api = require("./api")

class Restaurant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450,
      rest_name: null,
      loading:false,
      place:null
    }

    if(!(
      this.props.location && 
      this.props.location.state &&
      this.props.location.state.user_id
    )){
      this.props.history.replace({
        pathname:'/start'
      })  
    } 


    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  updatePlace(place){
    Api.callApi({
      endpoint:'save-place',
      data:{
        user_id:this.props.location.state.user_id,
        place_id:place.place_id
      },
      success:(data)=>{
        console.log(place)
        this.props.history.push({
          pathname:'/gmb-oauth',
          state:{
            rest_uuid:data.rest_uuid,
            place_id:place.place_id,
            restaurant_name: place.name,
            user_id:this.props.location.state.user_id
          }
        })
      }
    })
  }

  renderDesktop(){
    return(
      <div className='restaurant-container'>
        <Header/>
        
        <div className='restaurant-inner-container'>
            <div className='restaurant-left-side'>
              <ProgressBar page='1'/> 
              <div className='restaurant-details-container'>
                  
                  <div className='restaurant-details'>Restaurant Details</div>
                  <div className='restaurant-details-subtext'>Where are you located?</div>
                  <div className='restaurant-input-title'>Search and select your restaurant location below:</div>

                  {/*
                  <input
                    className='restaurant-text-input text-input'
                    value={this.state.first_name}
                    placeholder='Your restaurant or address'
                    onChange={(e)=>this.setState({first_name:e.target.value})}
                    required
                  />*/}
                  <GoogleAutocomplete 
                    updatePlace={(place)=>this.updatePlace(place)}
                  />
                  
                  {/* <div className='restaurant-continue-box'>
                    <button className='restaurant-continue-button'>Continue</button>
                  </div> */}
              </div>
              <div className='restaurant-contact-us'>
                <div style={{marginRight:'5px'}}>Need help? </div>
                  <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
              </div>
            </div>
              
            <div className='right-side-map'>
              <img style={{width: '465px', marginLeft:'10px'}} src='/map.png'></img>
            </div>
          </div>
        
      </div>
    )
  }

  renderMobile(){
    return(
      <div className='restaurant-container'>
        <Header/>
        <div className='mobile-restaurant-inner-container'>
            <div className='mobile-restaurant-left-side'>
              <ProgressBar page='1'/>
              <div className='mobile-restaurant-details-container'>
                  <div className='mobile-restaurant-details'>Restaurant Details</div>
                  <div className='mobile-restaurant-details-subtext'>Where are you located?</div>
                  <div className='mobile-restaurant-input-title'>Search and select your restaurant location below:</div>

                  {/*
                  <input
                    className='restaurant-text-input text-input'
                    value={this.state.first_name}
                    placeholder='Your restaurant or address'
                    onChange={(e)=>this.setState({first_name:e.target.value})}
                    required
                  />*/}
                  <GoogleAutocomplete 
                    updatePlace={(place)=>this.updatePlace(place)}
                  />

                  {/* {!this.state.place && (
                    <div className='mobile-map-location-icon'><img src='/map-location-icon.png'></img></div>
                  )} */}
                  
                  {/* <div className='restaurant-continue-box'>
                    <button className='restaurant-continue-button'>Continue</button>
                  </div> */}
              </div>
              <div className='mobile-restaurant-contact-us'>
                <div style={{marginRight:'5px'}}>Need help? </div>
                  <a style={{textDecoration:'underline'}} href='https://www.gocaptain.com/contact' target='_blank'>Contact Us</a>
              </div>
            </div>    
          </div>
      </div>
    )
  }


  render(){
    return (
      this.state.isMobile ? 
        this.renderMobile()
        : this.renderDesktop()
    )
  }
}
      

export default Restaurant; 


