import React, { Component } from "react"
import "./css/ConfirmPhoneModal.css"
const Api = require("./api")

class ConfirmPhoneModal extends Component {

  constructor(props) {
    super(props)
    
    if(!(this.props.location && this.props.location.state)){
      this.props.history.replace({
        pathname:'/start'
      })
    }

    this.state = {
      isMobile: window.innerWidth < 450,
      confirm_code: '',
      auth_uuid: this.props.location.state.auth_uuid
    }
    this.handleResize = this.handleResize.bind(this)
  }
  

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  closeModal(){
    this.props.history.goBack()
  }

  handleConfirmPhone(e){
    this.setState({confirm_code: e.target.value}) 
  }
  
  buttonStyle(){
    if(this.state.confirm_code.length === 4){
      return {
        background:'linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%)'
      }
    } else{
      return {
        backgroundColor:'#AAABAF'
      }
    }
  }

  saveUser(){
    Api.callApi({
      endpoint:'save-user',
      data:{
        first_name:this.props.location.state.first_name,
        last_name:this.props.location.state.last_name,
        email:this.props.location.state.email,
        phone:this.props.location.state.phone
      },
      success:(data)=>{
        this.props.history.push({
          pathname:'/restaurant',
          state:{
            user_id:data.user_id
          }
        })
      },
      failure:(err)=>{
        alert('Unexpected error')
      }
    })
  }

  verifyCode(){
    Api.callApi({
      endpoint:'check-code',
      data:{
        code:this.state.confirm_code,
        auth_uuid:this.state.auth_uuid
      },
      failure:(err)=>{
        alert('Unexpected error')
      },
      success:(data)=>{
        if(data.success === 1){
          this.saveUser()
        } else{
          alert('Incorrect code')
        }
      }
    })
  }

  resendCode(){
    Api.callApi({
      endpoint:'get-code',
      data:{
        phone:this.props.location.state.phone
      },
      success:(data)=>{
        if(data.success === 1){ 
          this.setState({auth_uuid: data.auth_uuid})
          alert('Code resent')
        } else if (data.success === 0){ 
          alert(data.error)
        }
      },
      failure:(err) =>{ 
        alert('Unexpected error occured')
      }
    })  
  }

  clickedOutside(e){
    if (e.target.className === 'confirm-modal-container'){
      this.closeModal()
    }
    else{
      return
    }
  }

  renderDesktopModal(){
    return (
      <div className='confirm-modal-container' onClick={(e)=>this.clickedOutside(e)}>
        <div className='confirm-modal-inner-container'>
          <div className='close-modal-container'>
            <img onClick={()=>this.closeModal()} src='/close-modal.png' className='close-modal'></img>
          </div>
          <div className='confirm-modal-text-container'>
            <div className='verify-text'>Verify Your Cell Phone number</div>
            <img src='/phone-icon.png' className='phone-icon'></img>
            <div className='verify-phone-subtext'>Please enter the 4 digit code sent to your phone</div>
            <input
              className='confirm-phone-input'
              value={this.state.confirm_code}
              placeholder='Enter Code Here'
              onChange={(e) => {this.handleConfirmPhone(e)}}
              required
            ></input>
            <img className='confirm-code-input-line' src='/line-icon-phone-confirm.png'></img>
            <div className='resend-code-container'
              onClick={()=>this.resendCode()}
            >
              <a className='resend-code-text'>Resend Code</a>
              <img className='resend-code-icon'src='/resend-code.png'></img>
            </div>
            <button style={this.buttonStyle()} className='verify-button' onClick={()=>this.verifyCode()}> 
              Verify Your Code
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderMobileModal(){
    return(
      <div className='mobile-confirm-modal-container' onClick={(e)=>this.clickedOutside(e)}>
        <div className='mobile-confirm-modal-inner-container'>
          <div className='mobile-close-modal-container'>
            <img onClick={()=>this.closeModal()} src='/close-modal.png' className='mobile-close-modal'></img>
          </div>
          <div className='mobile-confirm-modal-text-container'>
            <div className='mobile-verify-text'>Verify Your Cell Phone number</div>
            <img src='/phone-icon.png' className='mobile-phone-icon'></img>
            <div className='mobile-verify-phone-subtext'>Please enter the 4 digit code sent to your phone</div>
            <input
              className='mobile-confirm-phone-input'
              value={this.state.confirm_code}
              placeholder='Enter Code Here'
              onChange={(e) => {this.handleConfirmPhone(e)}}
              required
            ></input>
            <img className='mobile-confirm-code-input-line' src='/line-icon-phone-confirm.png'></img>
            <div className='mobile-resend-code-container'
              onClick={()=>this.resendCode()}
            >
              <a className='mobile-resend-code-text'>Resend Code</a>
              <img className='mobile-resend-code-icon'src='/resend-code.png'></img>
            </div>
            <button style={this.buttonStyle()} className='mobile-verify-button' onClick={()=>this.verifyCode()}> 
              Verify Your Code
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      this.state.isMobile ? 
        this.renderMobileModal()
        : this.renderDesktopModal()
    )
  }
}


export default ConfirmPhoneModal; 
