import React, { Component } from "react"
import "./css/Subheader.css"

class Subheader extends Component {

    render(){
        return (
            <div className='gfosubheader'>
                <img className='gfo-logo' src='/gfologo.png'></img>
            </div>  
        )
    }


}
export default Subheader;