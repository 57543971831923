import React, { Component } from "react"
import "./css/CompleteActivation.css"
import Header from "./Header.js"
import Footer from "./Footer.js"
import Subheader from "./Subheader.js"
import ProgressBar from "./ProgressBar"

class CompleteActivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 450
    }

    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize(){
    this.setState({
      isMobile: window.innerWidth < 450
    })
  }

  renderDesktop(){
    return(
      <div className='complete-activation-outer-container'>
        <Header/>
       
        <div className='complete-activation-inner-container'>
          <div className='complete-activation-left-side'>
            <ProgressBar page='5'/>
            <div className='complete-activation-container'>
               
              <div className='complete-activation-text-container'>
                <div className='complete-activation-text'>Onboarding Complete!</div>
                <div className='complete-activation-subtext'>Congratulations, your business is now successfully 
                  onboarded to Google Food Ordering, powered by Captain. A member of our Captain crew will be in touch soon to discuss next steps.</div>                
              </div>
              <a href='https://www.gocaptain.com/contact' target="_blank">
                <button className='chat-button'>
                  Chat with Us
                </button>
              </a>
            </div>
          </div>
          <div className='complete-activation-right-side'>
            <img className='complete-activation-mockup' src='/complete.png'></img>
          </div>
        </div>
    
      </div> 
    )
  }

  renderMobile(){
    return(
      <div className='complete-activation-outer-container'>
        <Header/>
        <div className='mobile-complete-activation-inner-container'>
          <div className='mobile-complete-activation-left-side'>
            <ProgressBar page='5'/>
            <div className='mobile-complete-activation-container'>
              <div className='mobile-complete-activation-text-container'>
                <div className='mobile-complete-activation-text'>Onboarding Complete!</div>
                <div className='mobile-complete-activation-subtext'>Congratulations, your business is now successfully onboarded 
                  to Google Food Ordering, powered by Captain. A member of our Captain crew will be in touch soon to discuss next steps.</div>                
              </div>
              <a href='https://www.gocaptain.com/contact' target="_blank">
                <button className='mobile-chat-button'>
                  Chat with Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }


  render(){
    return (
      this.state.isMobile ? 
              this.renderMobile()
              : this.renderDesktop()
    )
  }
}
      

export default CompleteActivation; 


