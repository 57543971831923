import React, { Component } from "react"
import Checkmark from "./svg/Checkmark"
import "./css/ProgressBar.css"


class ProgressBar extends Component {

    // constructor(props){
    //     this.displayProgressBar = this.displayProgressBar.bind(this)
    // }

    displayProgressBar(){
        if (this.props.page == 1) {
            return(this.displayRestaurant())
        }
            
        else if (this.props.page == 2) {
            return(this.displayGMB())
        }

        else if (this.props.page == 3) {
            return(this.displayPOS())
        }

        else if (this.props.page == 4) {
            return(this.displayDownload())
        }

        else if (this.props.page == 5) {
            return(this.displayComplete())
        }

        else{
            return(null)
        }
    }

    displayRestaurant(){
        return(
            <div className='progress-bar-container'>
                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-selected'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-selected'>
                            <div className='progress-selected-dot'></div>
                        </div>
                        <div className='progress-text-selected'>Location</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Google</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>POS</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Download</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Complete</div>
                    </div>
                </div>
            </div>    
        )
    }

    displayGMB(){
        return(
            <div className='progress-bar-container'>
                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark /> 
                        </div>
                        <div className='progress-text-complete'>Location</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-selected'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-selected'>
                            <div className='progress-selected-dot'></div> 
                        </div>
                        <div className='progress-text-selected'>Google</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>POS</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Download</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Complete</div>
                    </div>
                </div>
            </div>    
        )
    }

    displayPOS(){
        return(
            <div className='progress-bar-container'>
                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark /> 
                        </div>
                        <div className='progress-text-complete'>Location</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>Google</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-selected'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-selected'>
                            <div className='progress-selected-dot'></div> 
                        </div>
                        <div className='progress-text-selected'>POS</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Download</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Complete</div>
                    </div>
                </div>
            </div>    
        )
    }

    displayDownload(){
        return(
            <div className='progress-bar-container'>
                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark /> 
                        </div>
                        <div className='progress-text-complete'>Location</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>Google</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>POS</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-selected'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-selected'>
                            <div className='progress-selected-dot'></div> 
                        </div>
                        <div className='progress-text-selected'>Download</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container'>
                            
                        </div>
                        <div className='progress-text'>Complete</div>
                    </div>
                </div>
            </div>    
        )
    }

    displayComplete(){
        return(
            <div className='progress-bar-container'>
                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark /> 
                        </div>
                        <div className='progress-text-complete'>Location</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>Google</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>POS</div>
                    </div>
                    
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-complete'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-complete'>
                            <Checkmark/> 
                        </div>
                        <div className='progress-text-complete'>Download</div>
                    </div>
                </div>

                <div className='progress-bar-subcontainer'>
                    <div className='progress-bar-line-selected'></div>
                    <div className='progress-text-container'>
                        <div className='svg-circle-container-selected'>
                            <div className='progress-selected-dot'></div> 
                        </div>
                        <div className='progress-text-selected'>Complete</div>
                    </div>
                </div>
            </div>    
        )
    }

    render() {
        // const props= this.props;
        return (
            this.displayProgressBar()
            
        )
    }

}

export default ProgressBar; 